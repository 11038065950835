<template>
  <v-app>
    <dashboard-core-view />
    <dashboard-core-footer />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreView: () => import('@/views/core/View'),
      DashboardCoreFooter: () => import('@/views/core/Footer'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
